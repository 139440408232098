import React from "react";

import { OMNIS_STEHOVANI_SLUG } from "../../constants";
import OmnisLP from "../../components/OmnisLP";
import { OmnisData } from "../../content/OmnisData";

const Stehovani = () => {
  return <OmnisLP contentData={OmnisData[OMNIS_STEHOVANI_SLUG]} articles={[]} />;
};

export default Stehovani;
